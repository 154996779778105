<template>
  <div style="display: flex; justify-content: center">
    <div style="position: relative; max-width: 520px">
      <img class="bag_img" src="@/assets/HsImagEson/di.png" alt="">
      <div class="conten_box">
        <div class="header_box center_view">
          <img src="@/assets/HsImagEson/logo.png" alt="">
        </div>
        <div class="center_view">
          <img class="hander_img" src="@/assets/HsImagEson/ICON.png" alt="">
        </div>
        <div class="btn_view center_view">
          <img style="z-index: 99999" ref="downloadButton" width="50%" src="@/assets/HsImagEson/anzhuo.png" alt="">
          <div id="qrCodeElement"></div>
          <img class="kefu_btn" @click="supperGame" width="15%" src="@/assets/HsImagEson/kefu_.png" alt="">
        </div>
        <div class="text_1 center_view">
          <img width="95%" src="@/assets/HsImagEson/1.png" alt="">
        </div>
        <!--                <div class="text_1 center_view">-->
        <!--                  <img width="95%" src="@/assets/HsImagEson/2.png" alt="">-->
        <!--                </div>-->
        <!--                <div class="text_1 center_view">-->
        <!--                  <img width="95%" src="@/assets/HsImagEson/50000.png" alt="">-->
        <!--                </div>-->
        <div class="bomm_img center_view">
          <img width="95%" src="@/assets/HsImagEson/youxi.png" alt="">
        </div>
        <div class="bomm2_img center_view">
          <img width="100%" src="@/assets/HsImagEson/SHOUJI.png" alt="">
        </div>
      </div>
    </div>
    <noscript>
      <img height="1" width="1" style="display:none"
      :src="facebookvalue"/></noscript>
  </div>
</template>
<script setup>
const {appContext} = getCurrentInstance();
import {getCurrentInstance, onMounted, ref} from 'vue';

const downloadButton = ref(null);
const facebookvalue = ref('');
onMounted(() => {
  // 使用OpenInstall的API解析当前网页URL中的查询参数，并对data进行赋值
  // eslint-disable-next-line no-undef
  var data = OpenInstall.parseUrlParams();
  var pixelId = data.pixelId;
  if(pixelId){
      facebookvalue.value = "https://www.facebook.com/tr?id=" + pixelId + "&ev=CompleteRegistration&noscript=1";
      !function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s)
      }(window, document, 'script','https://connect.facebook.net/en_US/fbevents.js');
      // eslint-disable-next-line no-undef
    fbq('init', pixelId);
  // eslint-disable-next-line no-undef
    fbq('track', 'PageView');
    //获取当前cookie是否存在fbc和fbp
  const cookies = document.cookie;
    if(cookies){
      const cookieList = cookies.split(';');
      for(var i = 0 ; i < cookieList.length; i++){
        var param = cookieList[i].split("=");
        if(param && param[0].trim() == '_fbp'){
          data.fbp = param[1];
        }else if(param && param[0].trim() == '_fbc'){
          data.fbc = param[1];
        }
      }
    }
    console.log(data);
  }else {
    facebookvalue.value = "";
  }
  // 初始化OpenInstall，与OpenInstall服务器交互，应尽早调用
  // eslint-disable-next-line no-undef
  new OpenInstall({
    appKey: "h8g5rb", // OpenInstall为应用分配的唯一ID（必须传入）
    onready: function () { // 初始化成功回调方法。当初始化完成后，会自动进入
      this.schemeWakeup(); // 尝试使用scheme打开App（主要用于Android以及iOS的QQ环境中）
      var m = this; // 保存当前上下文

      // 确保图片元素存在后再绑定事件
      if (downloadButton.value) {
        downloadButton.value.onclick = function () { // 为图片绑定点击事件
          m.wakeupOrInstall(data); // 此方法为scheme、Universal Link唤醒以及引导下载的作用（必须调用且不可额外自行跳转下载）
          return false; // 阻止默认事件
        }
      }
    }
  }, data); // 初始化时传入data，作为一键拉起/App传参安装时候的参数
});
  

const supperGame = () => {
  // 跳转客服
  const supportUrl = appContext.config.globalProperties.$config.serVer;
  window.open(supportUrl);
};
</script>

<style scoped>
.bag_img {
  background: url("@/assets/HsImagEson/di.png");
  width: 100%;
  height: 100%;
}

.conten_box {
  position: absolute;
  top: 27px;
  left: 0;
  right: 0;
}

.header_box img {
  width: 72%;
  height: 100%;
}

.center_view {
  display: flex;
  justify-content: center;
}

.hander_img {
  width: 25%;
}

.kefu_btn {
  right: 2%;
  position: absolute;
  top: -55%
}

.btn_view {
  position: relative;
}

.bomm_img {
  z-index: 10;
  position: sticky;
}

.bomm2_img {
  position: absolute;
  bottom: -29%;
  z-index: 1;
}
</style>
